<div id="shared-dialog">
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
        <div class="text-center">
            <img class="w-25-p" src="/assets/logos/mini-logo.svg" alt="LOGO">
        </div>
        <p class="dialog-description text-center">{{data.description}}</p>
        <p class="dialog-description text-center">(Email non reçu ? <a href="#" [ngClass]="{'dialog-description-disable': counter > -1}" (click)="counter == -1 ? resendMailSignature($event) : false">Cliquez ici</a>)<span *ngIf="counter != -1">  {{ counter }}s ...</span></p>
    </div>
    <mat-dialog-actions align="center">
        <button mat-stroked-button [mat-dialog-close]="false">
          <mat-icon class="mat-18">touch_app</mat-icon>
        Souscrire un nouveau contrat
      </button>
    </mat-dialog-actions>
</div>