import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { PagesComponentb2c } from './pages/pages.component';
import { ConfirmPaiementComponent } from './confirm-paiement/confirm-paiement.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const routes: Routes = [
  {
    path: '',
    component: ConfirmPaiementComponent,
    children: [

    ]
  }
];

@NgModule({
  declarations: [PagesComponentb2c,ConfirmPaiementComponent],
  imports: [
    RouterModule.forChild(routes),
    AngularMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class PagesB2CModule { }
