import { Component,OnInit, ViewChild , OnDestroy } from '@angular/core';
import { PrincipalInformation } from 'src/app/pages/principal-information/_models/PrincipalInformation';
import { Sign } from '../_models/Sign';
import { B2cService } from '../_service/b2c.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import * as storageService from '../../pages/pages/_utils.ts/dataStorage';
import { TarificationResponse } from 'src/app/pages/pages/_models/TarificationResponse';
import { ErrorDialogService } from 'src/app/shared-components/error-dialog/error.dialog.service';
import { GenerateDevis } from 'src/app/pages/pages/_models/GenerateDevis';
import { ESignResponse } from '../_models/ESignResponse';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared-components/dialog/dialog.component';
import { Router , ActivatedRoute , NavigationEnd } from '@angular/router';
import { LoadingScreenService } from 'src/app/shared-components/loading-screen/loading-screen.service';
import { environment } from 'src/environments/environment';
import { InfoSouscriptionResponse } from 'src/app/pages/pages/_models/InfoSouscriptionResponse';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'confirm-paiement',
  templateUrl: './confirm-paiement.component.html',
  styleUrls: ['./confirm-paiement.component.scss']
})
export class ConfirmPaiementComponent implements OnDestroy{
  @ViewChild("souscripteurForm")  souscripteurForm: NgForm = {} as NgForm;;
  signRequest: Sign = new Sign();
  chosenCollectionDay : boolean =false;
  typeoffre : boolean =false;
  generateDevis: GenerateDevis = new GenerateDevis();
  loader: boolean = false;
  stepIndex: number = 0 ;
  eSignResponse: ESignResponse = new ESignResponse() ;
  currency: string = environment.currency;
  formatCurrency: string = environment.formatCurrency;
  tarificationResponse: TarificationResponse = new TarificationResponse();
  infoSouscriptionResponse: InfoSouscriptionResponse = new InfoSouscriptionResponse() ;

  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(private _b2cService: B2cService, private _cookieService: CookieService,
    private _matDialog: MatDialog,
    private _router: Router,
    public loadingScreenService: LoadingScreenService,
    private _errorService: ErrorDialogService,
    private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    if (this._cookieService.check('s6P4XRSAjMhiSvGrvybGy0BSwrXhHdSySoJR')) {
      this.generateDevis = JSON.parse(storageService.decrypt(this._cookieService.get('s6P4XRSAjMhiSvGrvybGy0BSwrXhHdSySoJR')));
    }

    if (this._cookieService.check('zkdi39ac3eu9X7H6hpVcVLmvQlWCjxXwI')) {
      this.signRequest = JSON.parse(storageService.decrypt(this._cookieService.get('zkdi39ac3eu9X7H6hpVcVLmvQlWCjxXwI')));
    }

    if (this._cookieService.check('rzuzKYd1FYP4nZpKvlynNCrzuzKYd16w')) {
      this.eSignResponse = JSON.parse(storageService.decrypt(this._cookieService.get('rzuzKYd1FYP4nZpKvlynNCrzuzKYd16w')));
    }

    if (this._cookieService.check('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD')) {
      this.stepIndex = parseInt(storageService.decrypt(this._cookieService.get('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD')));
      if (this.stepIndex == 4) {
        this._router.navigate(['/souscription']) ;
      } else if (this.stepIndex == 5 && this.eSignResponse.sign_url !== null && this.eSignResponse.sign_url !== "") {
        this._router.navigate(['/souscription']) ;
        this.showDialog() ;
      }
    }

    if (this._cookieService.check('DBXdywlRerb8nkfptnAc0Y0ugP0mzn')) {
      this.tarificationResponse = JSON.parse(storageService.decrypt(this._cookieService.get('DBXdywlRerb8nkfptnAc0Y0ugP0mzn')));
    }

    if (this._cookieService.check('VIidV08pjAT82HXeLETH7s7eYMccTK8')) {
      this.infoSouscriptionResponse = JSON.parse(storageService.decrypt(this._cookieService.get('VIidV08pjAT82HXeLETH7s7eYMccTK8')));
    }

    this._activatedRoute.params.subscribe(() => {
      if (this._cookieService.check('VIidV08pjAT82HXeLETH7s7eYMccTK8')) {
        this.infoSouscriptionResponse = JSON.parse(storageService.decrypt(this._cookieService.get('VIidV08pjAT82HXeLETH7s7eYMccTK8'))); 
      }
    });

    history.pushState(null, '');

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((event) => {
        if(this.stepIndex === 4){
          this.previousStep() ; 
        } else {
          history.pushState(null, '');
        }
      });
  }

  ngOnDestroy(): void {
    // if(this._cookieService.check('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD')){
    //   this._cookieService.delete('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD');
    // }
    // if(this._cookieService.check('stepIndex')){
    //   this._cookieService.delete('stepIndex');
    // }
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }


  selectOffreType () :void {
    this.typeoffre=!this.typeoffre; 
  }

  selectPeriodicity(periodicite: number | null) : void{
    this.signRequest.infoBancaire.periodicite = periodicite ; 
    if(this.signRequest.infoBancaire.periodicite == 1)
    this.typeoffre = true;
    if(this.signRequest.infoBancaire.periodicite == 12)
    this.typeoffre = false;

  }

  selectJrPrelevement(jourPrelevement: number | null) {
    this.signRequest.infoBancaire.jourPrelevement = jourPrelevement ; 
  }

  sign (showDialog: boolean) :void {
    if((this.souscripteurForm?.valid || (this.signRequest.infoBancaire.jourPrelevement == 0)) && this.signRequest.infoBancaire.jourPrelevement !== null && this.signRequest.infoBancaire.mode_paiement
      && this.signRequest.infoBancaire.periodicite){
      this.loadingScreenService.show() ;
      this.signRequest.opportuniteRef = this.generateDevis.opportuniteRef;

      if (this.signRequest.infoBancaire.jourPrelevement == 0) {
        delete this.signRequest.infoBancaire.iban;
        delete this.signRequest.infoBancaire.bic;
        delete this.signRequest.infoBancaire.titulaireCompte;
        delete this.signRequest.infoBancaire.nomBanque;
      }

      this.signRequest.eSignRef = this.eSignResponse.eSignRef ; 
      this.signRequest.urlYousign = this.eSignResponse.sign_url ; 

      if (this.signRequest.eSignRef == null || this.signRequest.eSignRef == "" || this.signRequest.urlYousign == null || this.signRequest.urlYousign == "") {
        delete this.signRequest.eSignRef ; 
        delete this.signRequest.urlYousign ;
      }
      
      this._b2cService.sign(this.signRequest)
      .subscribe((response: ESignResponse)=>{
        this.loadingScreenService.hide() ;
        this.eSignResponse = response ;
        if (this.eSignResponse.sign_url !== null && this.eSignResponse.sign_url !== "") {
          if (showDialog)
          this.showDialog() ;

          storageService.encryptAndStore("5",'xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD',this._cookieService) ;
          storageService.encryptAndStore(JSON.stringify(this.signRequest),'zkdi39ac3eu9X7H6hpVcVLmvQlWCjxXwI',this._cookieService) ;
          storageService.encryptAndStore(JSON.stringify(this.eSignResponse),'rzuzKYd1FYP4nZpKvlynNCrzuzKYd16w',this._cookieService) ;
        } else {
          this._errorService.openErrorDialog("Un problème survenu lors de la signture de contrat", "Fermer", true)
        }

      },(error: HttpErrorResponse)=>{
        this.loadingScreenService.hide() ;
        if(error.error?.message)
        this._errorService.openErrorDialog(error.error.message, "Fermer", true)
        else
        this._errorService.openErrorDialog("Un problème survenu lors de la signture de contrat", "Fermer", true)
      });
    }else{
      this._errorService.openErrorDialog("Veuillez remplir les champs requis", "Fermer", false)
    }
  }

  previousStep() : void {
    storageService.encryptAndStore("3",'xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD',this._cookieService) ;
    let apiKey = "";
    if(this._cookieService.check("6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8"))
    apiKey = storageService.decrypt(this._cookieService.get('6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8'));
    this._router.navigate(['/'],{ queryParams: { partnership_id: apiKey} }) ;

  }

  showDialog(): void {
    this._matDialog.open(DialogComponent, {
      width: "600px",
      minHeight: "300px",
      disableClose: true,
      data: {
        title: "Le contrat est en attente de signature",
        description: "Un courriel a été envoyé au souscripteur. Il suffit de consulter sa boîte de réception pour signer le contrat et finaliser le processus",
        function: () => this.sign(false)
      }
    }).afterClosed().subscribe(() =>{
      let apiKey = "";
      if(this._cookieService.check("6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8"))
      apiKey = storageService.decrypt(this._cookieService.get('6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8'));
      this._cookieService.deleteAll();

      this._router.navigate(["/"], { queryParams: { partnership_id: apiKey} });
    })

  }

  onKeyDownInfosBancaire(event: KeyboardEvent): void {
    const allowedKeys = /^[a-zA-Z0-9]*$/;
    if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
      return;
    }
    if (!allowedKeys.test(event.key)) {
      event.preventDefault();
    }
  }



}
