import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit , Input , Output , EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { GarantiesObligatoires } from './_models/GarantiesObligatoires';
import { TarificationResponse } from '../pages/_models/TarificationResponse';
import { GarantiesOptionnelles } from './_models/GarantiesOptionnelles';
import { Tarification } from '../pages/_models/Tarification';
import { GenerateDevis } from '../pages/_models/GenerateDevis';
import { environment } from 'src/environments/environment';
import { ErrorDialogService } from 'src/app/shared-components/error-dialog/error.dialog.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { CookieService } from 'ngx-cookie-service';
import * as storageService from '../pages/_utils.ts/dataStorage'
import { PagesService } from '../pages/_service/pages.service';
import { Prospect } from './_models/prospect';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'tarification',
  templateUrl: './tarification.component.html',
  styleUrls: ['./tarification.component.scss']
})
export class TarificationComponent implements OnInit , OnChanges {
  @Input() tarificationResponse: TarificationResponse = new TarificationResponse();
  @Input() tarificationRequest: Tarification = new Tarification();
  @Input() generateDevis: GenerateDevis = new GenerateDevis();
  @Input() tarificationLoading: boolean = false;
  @Input() disableInfoSouscripteurInputs: boolean = false ;

  currency: string = environment.currency;
  formatCurrency: string = environment.formatCurrency;
  @Output() tarificationRequestChange : EventEmitter<any> = new EventEmitter();
  @Output() disableInfoSouscripteurInputsChange: EventEmitter<any> = new EventEmitter(); 

  typeCotisation: boolean = false;
  garanties_obligatoires : GarantiesObligatoires[] = [
    {label : "Incendie et Evènements assimilés" , code : "IEEA" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" },
    {label : "Evènements climatiques" , code : "EC" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor"},
    {label : "Catastrophes naturelles" , code : "CATNAT" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" },
    {label : "Catastrophes technologiques" , code : "CATTECH" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor"},
    {label : "Attentats (hors GAREAT)" , code : "AHGAREAT" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor"},
    {label : "GAREAT" , code : "GAREAT" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" },
    {label : "Dégâts des eaux" , code : "DEAU" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor"},
    {label : "Responsabilité Civile Incendie" , code : "RCI" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor"},
    {label : "Responsabilité Civile Vie Privée" , code : "RCVP" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" },
    {label : "Bris de Glace" , code : "BG" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor"},
    {label : "Défense pénale et recours" , code : "DPER" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor"},
    {label : "Responsabilité Propriétaire d'immeuble et Co Propriétaire Non Occupant" , code : "RPIECPNO" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor"},
    {label : "Dommages électriques" , code : "DOMEL" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" },

  ] ;

  garanties_Optionnelles : GarantiesOptionnelles[] = [
    {label : "Valeur à neuf à 100%" , code : "VN" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" , typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_valeur_neuf" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Vol" , code : "VOL" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_vol" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Assistance" , code : "AM" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_assistance" , typeProspectCondition : ["Locataire","Propriétaire occupant"] , disabledCondition : ["MRH Premium","Locataire","Propriétaire occupant"] },
    {label : "Protection Juridique" , code : "PJ" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_pj" , typeProspectCondition : ["Locataire","Propriétaire occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Canalisations enterrées" , code : "CANE" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison"] , modelKEy : "opt_canalisation_ent" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Débordements et refoulements d'égoûts" , code : "DEREG" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison"] , modelKEy : "opt_debordement" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Bris de glace sur objet mobilier" , code : "BGOM" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_bris_glace_mobilier" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Bris de glace sur véranda" , code : "BGV" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison"] , modelKEy : "opt_bris_glace_veranda" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Panneaux photovoltaïques" , code : "PPHV" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison"] , modelKEy : "opt_panneaux_photo" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Piscine ou SPA" , code : "POSPA" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison"] , modelKEy : "opt_piscine_spa" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Biens en extérieurs" , code : "BEE" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison"] , modelKEy : "opt_bien_exterieur" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Extension chambre d'hôte ou gite" , code : "ECHOG" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_extension_chambre" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Extension location saisonnière" , code : "ELS" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_extension_location_sais" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Perte de loyer" , code : "PL" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison","Appartement"], modelKEy : "opt_perte_loyer" , typeProspectCondition : ["Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium","Propriétaire occupant","Propriétaire non occupant"] },
    {label : "RC Assistante Maternelle" , code : "RCASSM" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_RC_assistance_maternelle" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "RC Aidant" , code : "RCA" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" , typeHabitationCondition : ["Maison","Appartement"], modelKEy : "opt_RC_aidant" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "Kiosque" , code : "KI" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison"] , modelKEy : "opt_kiosque" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"]},
    {label : "Terrasse ajoutée" , code : "TA" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" , typeHabitationCondition : ["Maison"] , modelKEy : "opt_terrasse" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    {label : "RC Fête familiale" , code : "RCFFAM" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" , typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_RC_fete" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
    // {label : "PNO Location saisonnière" , code : "" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" },
    {label : "Contenu professionnel" , code : "CPRO" , description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integeraccumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor", typeHabitationCondition : ["Maison","Appartement"] , modelKEy : "opt_contenu_pros" , typeProspectCondition : ["Locataire","Propriétaire occupant","Propriétaire non occupant"] , disabledCondition : ["MRH Premium"] },
  ]

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.France];
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});
  editProspect: boolean = false;
  updateProspectLoading: boolean = false;

  constructor(private _errorDialogService: ErrorDialogService,private _cookieService: CookieService,
    private _pagesService: PagesService) { }


  ngOnChanges(changes: SimpleChanges): void {
    if(this.disableInfoSouscripteurInputs == true){
      this.phoneForm.controls.phone.disable();
    }
    if(this.disableInfoSouscripteurInputs == false){
      this.phoneForm.controls.phone.enable();
    }
  }
  



  ngOnInit(): void {
    if(this._cookieService.check('WkSUhpEhHV7RFSEP1SYWq1MpxpQkoIBLE')){
      this.phoneForm.patchValue(JSON.parse(storageService.decrypt(this._cookieService.get('WkSUhpEhHV7RFSEP1SYWq1MpxpQkoIBLE'))));
    }
    this.phoneForm.valueChanges.subscribe((formControl: any)=>{
      if (!this.phoneForm.controls.phone.disabled) {
        if(this.phoneForm.valid){
          this.generateDevis.client.mobile = formControl.phone.internationalNumber.replace(/\s/g, "");
          if(formControl.phone.number.includes("+")){
            this.phoneForm.controls.phone.setValue(formControl.phone.nationalNumber)
          }
  
          storageService.encryptAndStore(JSON.stringify(this.phoneForm.value),'WkSUhpEhHV7RFSEP1SYWq1MpxpQkoIBLE',this._cookieService) ;
        }
        else{
          this.generateDevis.client.mobile = null;
        }
      } 
      
    })
    if(this.phoneForm.controls?.phone?.value && this.generateDevis.opportuniteRef) {
      this.disableInfoSouscripteurInputs = true ; 
      this.phoneForm.controls.phone.disable(); 
      this.transferDisableValue() ; 
    }
      
  }


  getSlideValue(value: boolean,attr:string): void{
    this.tarificationRequest[attr] = value;
    this.transferValues() ;
  }

  allowEditProspect() :void{
    this.disableInfoSouscripteurInputs = false ; 
    this.transferDisableValue() ;
    this.phoneForm.controls.phone.enable();
  }

  resetProspectInformation() :void{
    this.disableInfoSouscripteurInputs = true ; 
    this.transferDisableValue() ;

    const oldDevisInformation = JSON.parse(storageService.decrypt(this._cookieService.get('s6P4XRSAjMhiSvGrvybGy0BSwrXhHdSySoJR')));
    if(oldDevisInformation?.client){
      this.generateDevis.client = oldDevisInformation.client;
    } 

    this.phoneForm.controls.phone.disable();
  }

  transferValues() :void{
    this.tarificationRequestChange.emit(
      {
        tarificationRequest: this.tarificationRequest,
      }
    )
  }

  transferDisableValue() :void{
    this.disableInfoSouscripteurInputsChange.emit(
      {
        disableInfoSouscripteurInputs: this.disableInfoSouscripteurInputs,
        sendDevis : this.generateDevis.sendDevis,
      }
    )
  }

  getExtraInfoValue(garantieOpt: any): string {
    if (this.tarificationResponse.ventilation![garantieOpt.code]) {
      if (this.tarificationResponse.ventilation![garantieOpt.code]?.montant_ttc)
      return this.tarificationResponse.ventilation![garantieOpt.code]!.montant_ttc.toString()
      else
      return "" ;


    } else {
      if (this.tarificationResponse.garanties_optionnelles![garantieOpt.code]?.montant_ttc)
      return this.tarificationResponse.garanties_optionnelles![garantieOpt.code]!.montant_ttc!.toString();
      else
      return "" ;
    }
  }

  validateSubscriberInfosFrom(strToCheck: any) : void {
    if (strToCheck !== null) {
      const countSpaces = strToCheck.split(' ').length - 1;
        if (countSpaces > 0 && strToCheck.trim() == "") {
          this._errorDialogService.openErrorDialog("Effacez les "+ countSpaces.toString()+" ESPACES","Fermer",false)
        }
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight'];
    if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
      return;
    }
    if (allowedKeys.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  }

  updateProspect() : void{
    if (this.phoneForm.valid) {
      const oldDevisInformation = JSON.parse(storageService.decrypt(this._cookieService.get('s6P4XRSAjMhiSvGrvybGy0BSwrXhHdSySoJR')));
    if(oldDevisInformation){
      if(this.checkProspectInformationChange(oldDevisInformation)){
        this.updateProspectLoading = true;
        let prospect = new Prospect();
        prospect.opportuniteRef = this.generateDevis.opportuniteRef;
        prospect.client = this.generateDevis.client;
        this._pagesService.updateProspect(prospect)
        .subscribe((response)=>{
          this.updateProspectLoading = false;
          this.disableInfoSouscripteurInputs = true ;
          this.generateDevis.sendDevis = false ; 
          this.transferDisableValue() ; 
          storageService.encryptAndStore(JSON.stringify(this.generateDevis),'s6P4XRSAjMhiSvGrvybGy0BSwrXhHdSySoJR',this._cookieService) ;

          this.phoneForm.controls.phone.disable();

        }, (error: HttpErrorResponse)=>{
          this.updateProspectLoading = false;
          if(error?.error?.message)
          this._errorDialogService.openErrorDialog(error?.error?.message, "Fermer", true);
          else
          this._errorDialogService.openErrorDialog("Un erreur survenu lors de la modification des informations de prospect", "Fermer", true);
        });
      }else{
        this._errorDialogService.openErrorDialog("Aucun changement détecté", "Fermer", false);
        this.disableInfoSouscripteurInputs = true ;
        this.phoneForm.controls.phone.disable();
        this.transferDisableValue() ; 
      }
    }
    }
    
  }

  checkProspectInformationChange(oldDevisInformation: GenerateDevis) : boolean{
    return (
      oldDevisInformation.client.title != this.generateDevis.client.title ||
      oldDevisInformation.client.firstName != this.generateDevis.client.firstName ||
      oldDevisInformation.client.lastName != this.generateDevis.client.lastName ||
      oldDevisInformation.client.address.streetName != this.generateDevis.client.address.streetName ||
      oldDevisInformation.client.address.postalCode != this.generateDevis.client.address.postalCode ||
      oldDevisInformation.client.address.city != this.generateDevis.client.address.city ||
      oldDevisInformation.client.email != this.generateDevis.client.email ||
      oldDevisInformation.client.mobile != this.generateDevis.client.mobile
      );
  }
}
