export class Questions{
    capital_mobilier:   number = 6000;
    niveau_protection:  number | null = null;
    capital_vol:        number = this.capital_mobilier / 2;
    alarme:             boolean = false;
    place_alarme:       boolean | null = null;
    terrasse:           boolean = false;
    surface_terrasse:   number | null = null;
    kiosque:            boolean = false;
    surface_kiosque:    number | null = null;
    veranda:            boolean = false;
    location_meuble:    boolean = false;
    garage:             boolean = false;
    box:                boolean = false;
    acceptPub:          boolean | null = null;
    Formule:            string | null = null;

    [key: string]: boolean | string | number | null;
  }
