export class Tarification {
  Formule:          string | null = null;
  habitation:       Habitation = new Habitation();
  contrat:          boolean | null = null;
  num_contrat:      number | null = null;
  opt_valeur_neuf :   boolean = false;
  opt_assistance:     boolean = false;
  opt_vol:            boolean = false;
  opt_pj:             boolean = false;
  opt_canalisation_ent: boolean = false;
  opt_debordement:      boolean = false;
  opt_bris_glace_mobilier:boolean = false;
  opt_bris_glace_veranda:boolean = false;
  opt_panneaux_photo:boolean = false;
  opt_piscine_spa: boolean = false;
  opt_bien_exterieur:boolean = false;
  opt_extension_chambre: boolean = false;
  opt_extension_location_sais: boolean = false;
  opt_perte_loyer: boolean = false;
  opt_RC_assistance_maternelle: boolean = false;
  opt_RC_aidant: boolean = false; 
  opt_kiosque:  boolean = false;
  opt_terrasse: boolean = false;
  opt_RC_fete: boolean = false;
  // opt_PNO_location_sais:bool = False
  opt_contenu_pros: boolean = false;
  dateEffet: string = "" ; 


  [key: string]: boolean | string | number | null | Habitation;

}

class Habitation {
  capital_mobilier: number | null = null;
  absence_habitation: boolean | null = null;
  alarme?:             boolean | null = null;
  place_alarme?:       boolean | null = null;
  annee_construction: number | null = null;
  bois:               boolean | null = null;
  box:                boolean | null = null;
  denivele_terrain:   boolean | null = null;
  dur:                boolean | null = null;
  etat_bien:          string | null = null;
  garage:             boolean | null = null;
  kiosque:            boolean | null = null;
  surface_kiosque?:    number | null = null;
  location_meuble:    boolean | null = null;
  niveau_protection:  number | null = null;
  residence:          string | null = null;
  resilie:            boolean | null = null;
  sinistres:          boolean | null = null;
  taille_habitation:  number | null = null;
  terrasse:           boolean | null = null;
  surface_terrasse?:   number | null = null;
  typeHabitation:     string | null = null;
  veranda:            boolean | null = null;
  zone_risque:        boolean | null = null;
  typeProspect:       string | null = null;

  postalCode: string | null = null;
  city:       string | null = null;

  [key: string]: boolean | string | number | null | undefined;
}
