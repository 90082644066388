class infoBancaire{
  periodicite:    number | null = null;
  jourPrelevement:number | null = null;
  mode_paiement:  string | null = null;
  iban?:           string | null = null;
  bic?:            string | null = null;
  nomBanque?:      string | null = null;
  titulaireCompte?:string | null = null;
}

export class Sign{
    opportuniteRef?: string | null = null;
    infoBancaire: infoBancaire =  new infoBancaire();
    urlYousign?: string | null = null ; 
    eSignRef?: string | null = null ; 
    [key: string]: string | null | undefined  | boolean | infoBancaire;

  }
