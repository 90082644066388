import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  counter: number = -1;

  constructor(public matDialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  resendMailSignature(event: Event) : void {
    if (this.data.function) {
      this.counter = 0;
      this.data.function() ;
      this.timer()
    }
    event.preventDefault();
  }

  timer() : void{
    const interval = setInterval(() => {
      this.counter++;
      if(this.counter == 20){
        clearInterval(interval);
        this.counter = -1;
      }
      interval
    }, 1000);
  }
}
