import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Tarification } from '../_models/Tarification';
import { Observable } from 'rxjs';
import { GenerateDevis } from '../_models/GenerateDevis';
import { GetInfoSouscription } from '../_models/GetInfoSouscription';
import { Prospect } from '../../tarification/_models/prospect';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  private apiUrl: string = environment.API_URL;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
  */
  constructor(private _httpClient: HttpClient) { }

  /**
   * Permettant de retourner une tarification pour prospect
   * @param {Tarification} tarification
   */
  tarification(tarification: Tarification): Observable<any> {
    const headers = new HttpHeaders();
    let queryParams = new HttpParams();
    queryParams =  queryParams.append('simulate', 'true');

    return this._httpClient.request<any>('post', `${this.apiUrl}/tarificateur/tarification_mrh`,
        {
            headers: headers,
            params: queryParams,
            body: tarification
        }
    );
  }

  /**
   * Permettant de génerer une devis
   * @param {GenerateDevis} devis
   */
  generateDevis(devis: GenerateDevis, sendDevis: boolean, isPub: boolean | null): Observable<any> {
    const headers = new HttpHeaders();
    let queryParams = new HttpParams();
    queryParams =  queryParams.append('sendDevis', sendDevis.toString());
    if(isPub != null)
    queryParams =  queryParams.append('isPub', isPub.toString());

    return this._httpClient.request<any>('post', `${this.apiUrl}/devis/generate_devis`,
        {
            headers: headers,
            params: queryParams,
            body: devis
        }
    );
  }

  /**
   * Permettant de modifier une opportunite
   * @param {GenerateDevis} devis
   */
  updateOpportunite(devis: GenerateDevis, sendDevis: boolean): Observable<any> {
    const headers = new HttpHeaders();
    let queryParams = new HttpParams();
    queryParams =  queryParams.append('sendDevis', sendDevis.toString());

    return this._httpClient.request<any>('post', `${this.apiUrl}/opportunite/update_opportunite`,
        {
            headers: headers,
            params: queryParams,
            body: devis
        }
    );
  }


  /**
   * Permettant de récupérer les informations de la souscription
   * @param {GetInfoSouscription} getInfoSouscription
   */
  getSubscriptionInfos(getInfoSouscription: GetInfoSouscription): Observable<any> {
    const headers = new HttpHeaders();
    let queryParams = new HttpParams();

    return this._httpClient.request<any>('post', `${this.apiUrl}/globalRoutes/get_info_souscription`,
        {
            headers: headers,
            params: queryParams,
            body: getInfoSouscription
        }
    );
  }

  /**
   * Permettant de modifier un prospect
   * @param {Prospect} prospect
   */
  updateProspect(prospect: Prospect): Observable<any> {
    const headers = new HttpHeaders();

    return this._httpClient.request<any>('post', `${this.apiUrl}/opportunite/update_prospect`,
        {
            headers: headers,
            body: prospect
        }
    );
  }

  upload_certif_radiation(certificat: File, opportuniteRef: string): Observable<any> {
    const headers = new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('certificat_radiation', certificat, certificat.name);
    formData.append('opportuniteRef', opportuniteRef);

    return this._httpClient.request<any>('post', `${this.apiUrl}/devis/upload_certificate`,
        {
            headers: headers,
            body : formData
        }
    );
  }
}
