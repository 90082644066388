<div class="card-checkbox" [ngClass]="{cardSelected :isSelected }" (click)="handleClick()">
    <div class="d-flex justify-content-center">
        <div>
            <h3 class="mb-1">{{ title }}</h3>
            <div class="d-flex align-items-center">
                <mat-icon class="icon" svgIcon="medalicon" *ngFor="let index of getColoredIcons()"></mat-icon>
                <mat-icon class="icon" svgIcon="medalicongrey" *ngFor="let index of getGreyIcons()"></mat-icon>

                <img class="card-item" [ngClass]="{'item-selected':isSelected}" src="/assets/global/check-icon.svg" alt="Check">
            </div>
        </div>
    </div>
</div>