import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrincipalInformationService {

  private apiUrl: string = environment.API_URL;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
  */
  constructor(private _httpClient: HttpClient) { }

  /**
   * Permettant de retourner la liste des ville par code postale
   * @param {string} zipcode
   */
  getCityByZipCode(zipcode: string): Observable<any> {
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append('zipcode', zipcode)
    const headers = new HttpHeaders();

    return this._httpClient.request<any>('get', `${this.apiUrl}/globalRoutes/getCityByZipCode`,
        {
            headers: headers,
            params: queryParameters
        }
    );
  }
}
