<div id="b2c">
    <div class="header">
        <img src="/assets/logos/logo.png" alt="">
    </div>
    <div class="steps-container">

        <mat-horizontal-stepper>
            <mat-step>

                <confirm-paiement></confirm-paiement>

                <!-- <div class="navigations">
                    <button mat-button class="btn-next" matStepperNext> <img src="/assets/svg-b2c/check.svg" alt="check"> Signer</button>
                </div> -->
            </mat-step>
        </mat-horizontal-stepper>

    </div>
</div>