import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import * as storageService from './_utils.ts/dataStorage'


@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
    constructor(private _cookieService: CookieService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // apikey
        if(this._cookieService.check('6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8')){
          const apiKey = storageService.decrypt(this._cookieService.get('6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8'));
          const isApiUrl = request.url.startsWith(environment.API_URL);
          if (isApiUrl && apiKey != null) {
              request = request.clone({
                  setHeaders: {
                    apikey: `${apiKey}`
                  }
              });
          }
        }
        // if(this._cookieService.check('apiKey')){
        //   const apiKey = this._cookieService.get('apiKey');
        //   const isApiUrl = request.url.startsWith(environment.API_URL);
        //   if (isApiUrl && apiKey != null) {
        //       request = request.clone({
        //           setHeaders: {
        //             apikey: `${apiKey}`
        //           }
        //       });
        //   }
        // }

        return next.handle(request);
    }
}
