<div id="questions">
    <div class="step-content">
        <div class="header">
            <h1>Questions subsidiaires</h1>
        </div>

        <div class="selection-box mt-5">
            <h1><span class="text-danger">*</span> Quel est le capital mobilier que vous souhaitez assurer ?</h1>
            <div class="checkbox-types">
                <ngx-slider [(value)]="value" [options]="options" (valueChange)="onValueChange($event);validationInputs();"></ngx-slider>
            </div>
        </div>

        <div class="selection-box mt-5">
            <h1 [ngClass]="{'text-danger': isQuestionsClicked && !questions.niveau_protection}"><span class="text-danger">*</span> Quel est le niveau de protection de votre habitation ?
                <mat-icon class="icon" [matTooltip]="textNiveauProtection">error_outline</mat-icon>
            </h1>
            <div class="checkbox-types w-75 m-auto justify-content-around">
                <div class="checkbox-content m-2" [ngStyle]="{'border': questions.niveau_protection == 1 ? '1px solid #E30613' : 'none'}" (click)="selectNiveauProtection(1); validationInputs() ; ">
                    <h2>Porte d'accès avec aucun ou un seul point de condamnation ou sur les parties vitrées (fenêtres…), pas de protection où toutes les ouvertures ne peuvent pas êtres closes.</h2>
                    <span class="checkbox-selected-hide" [ngClass]="{'checkbox-selected': questions.niveau_protection == 1}">
                      <img src="/assets/global/check-icon.svg" alt="Check">
                    </span>
                </div>
                <div class="checkbox-content m-2" [ngStyle]="{'border': questions.niveau_protection == 2 ? '1px solid #E30613' : 'none'}" (click)="selectNiveauProtection(2); validationInputs() ; ">
                    <h2>Portes d'accès avec au moins deux points de condamnation et toutes les parties vitrées (fenêtres…) possèdent des volets, persiennes, barreaux métaliques.</h2>
                    <span class="checkbox-selected-hide" [ngClass]="{'checkbox-selected': questions.niveau_protection == 2}">
                      <img src="/assets/global/check-icon.svg" alt="Check">
                  </span>
                </div>
            </div>
        </div>

        <!-- Input Surface de logement-->
        <div class="d-flex justify-content-center mt-4">
            <div class="field-container text-left">
                <mat-label><span class="text-danger">*</span> Votre capital vol est estimé à</mat-label>
                <mat-form-field class="field-element" appearance="outline">
                    <input matInput name="zipCode" disabled="true" [(ngModel)]="questions.capital_vol">
                    <span matSuffix class="secondairy-text">€</span>
                    <mat-error *ngIf=""></mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="questins-box mt-3 p-2 pb-3" *ngIf="questions.capital_vol > 50000 ">
            <img src="/assets/svg-question-component/alarme.svg" alt="Alarme">
            <h1>Avez-vous une alarme agréé ?</h1>
            <span class="d-inline-block">
              <div class="container-switch">
                <label class="switch space-switch"><input type="checkbox" (change)="getHasAlaramValue($event);validationInputs() ; "><span class="slider round"><span class="slider-text-checked">Oui</span><span class="slider-text-unchecked">Non</span></span>
            </label>
        </div>
        </span>
        <ng-container *ngIf="!questions.alarme">
            <h2 [ngClass]="{'text-danger': isQuestionsClicked && questions.place_alarme == null}"><span class="text-danger">*</span> Engagement à mettre en place une alarme sous 30 jours</h2>
            <mat-radio-group aria-label="Select an option" color="primary" (change)="validationInputs() ; " [(ngModel)]="questions.place_alarme">
                <mat-radio-button class="m-3" [value]="true">OUI j’accepte</mat-radio-button>
                <mat-radio-button [value]="false">NON je refuse</mat-radio-button>
            </mat-radio-group>
            <mat-error class="text-center mt-1">NB : En cas de refus montant de la garantie limité à 50 000 €</mat-error>
        </ng-container>
    </div>
    <div class="d-flex flex-row flex-wrap justify-content-center p-3 ml-5 mr-5">
        <div class="col-lg-4 mt-4" *ngIf="typeHabitation == 'Appartement' ? false : true">
            <slide-toggle (inputNumberValueEmit)="getInputNumberValue($event,'surface_terrasse')" (slideValue)="getSlideValue($event, true,'terrasse',false); validationInputs()" [inputNumber]="questions.terrasse" [value]="questions.terrasse" description="Avez-vous une terrasse ?"
                [image]="'terrase'" [inputNumberTitle]="'Surface de terrase'" [inputNumberValue]="questions.surface_terrasse" [required]="true"></slide-toggle>
        </div>
        <div class="col-lg-4 mt-4" *ngIf="typeHabitation == 'Appartement' ? false : true">
            <slide-toggle (inputNumberValueEmit)="getInputNumberValue($event,'surface_kiosque')" (slideValue)="getSlideValue($event, true,'kiosque',false); validationInputs()" [inputNumber]="questions.kiosque" [value]="questions.kiosque" description="Avez-vous un kiosque ?"
                [image]="'kiosque'" [inputNumberTitle]="'Surface du kiosque'" [inputNumberValue]="questions.surface_kiosque" [required]="true"></slide-toggle>
        </div>
        <div class="col-lg-4 mt-4" *ngIf="typeHabitation == 'Appartement' ? false : true">
            <slide-toggle (slideValue)="getSlideValue($event, true,'veranda',false); validationInputs()" [value]="questions.veranda" description="Avez-vous une véranda ?" [image]="'veranda'"></slide-toggle>
        </div>
        <div class="col-lg-4 mt-4">
            <slide-toggle (slideValue)="getSlideValue($event, true,'location_meuble',false); validationInputs()" [value]="questions.location_meuble" description="Etes-vous en location meublée ?" [image]="'meuble'"></slide-toggle>
        </div>
        <div class="col-lg-4 mt-4">
            <slide-toggle (slideValue)="getSlideValue($event, true,'garage',false); validationInputs()" [value]="questions.garage" description="Possédez vous un garage ?" [image]="'garage'"></slide-toggle>
        </div>
        <div class="col-lg-4 mt-4">
            <slide-toggle (slideValue)="getSlideValue($event, true,'box',false); validationInputs()" [value]="questions.box" description="Possédez vous un box ?" [image]="'box'"></slide-toggle>
        </div>
    </div>

    <div class="selection-box mb-3">
        <h1 [ngClass]="{'text-danger': isQuestionsClicked && !questions.Formule}"><span class="text-danger">*</span> Quelle formule serait préférable ?</h1>
        <div class="min-selection-box d-flex justify-content-around flex-wrap">

            <div class="min-checkbox-content d-flex justify-content-center align-items-center m-3 px-1" [ngStyle]="{'border': questions.Formule == 'MRH Basique' ? '1px solid #E30613' : 'none'}" (click)="selectFormule('MRH Basique') ;validationInputs() ; ">
                <span class="text-center">Basique</span>
                <span class="min-checkbox-selected-hide" [ngClass]="{'min-checkbox-selected': questions.Formule == 'MRH Basique'}">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.00033 0.700504C7.22062 0.698505 5.48092 1.22512 4.01116 2.21437C2.5314 3.20263 1.38153 4.60858 0.701647 6.25234C-0.00823594 7.8881 -0.188206 9.70767 0.201729 11.4484C0.531675 13.186 1.36156 14.7879 2.60136 16.0529C3.87115 17.294 5.46086 18.1483 7.20058 18.5141C8.51036 18.7849 9.86022 18.7599 11.16 18.4411C12.4598 18.1234 13.6695 17.5198 14.6993 16.6744C15.7392 15.8291 16.5691 14.7639 17.139 13.5568C17.7089 12.3487 18.0088 11.0297 17.9988 9.6937C18.0188 8.5096 17.7789 7.33552 17.299 6.25234C16.8691 5.16016 16.2292 4.16787 15.3993 3.3345C14.5294 2.51012 13.5096 1.84963 12.3998 1.38498C11.33 0.923333 10.1701 0.690511 9.00033 0.700504ZM13.5995 7.20761L7.80057 13.0142C7.74058 13.0861 7.66051 13.1451 7.58052 13.1861C7.49053 13.227 7.40064 13.249 7.30066 13.252C7.20067 13.249 7.11066 13.226 7.02067 13.184C6.94069 13.1411 6.86073 13.0811 6.80074 13.0062L4.40111 10.584C4.40111 10.568 4.40113 10.5491 4.30115 10.5281V10.3942C4.40113 10.3732 4.40111 10.3542 4.40111 10.3382L5.20091 9.57081V9.53281C5.20091 9.52382 5.30099 9.51885 5.30099 9.51885V9.53281C5.40097 9.54081 5.40095 9.55382 5.40095 9.57081L7.30066 11.4903L12.5997 6.17738C12.5997 6.16039 12.5997 6.1474 12.6997 6.13841V6.12542C12.6997 6.12542 12.7998 6.12538 12.7998 6.13737V6.18134L13.5995 6.96377V7.0197C13.6995 7.04068 13.6995 7.06368 13.6995 7.08667C13.6995 7.10965 13.6995 7.13262 13.5995 7.15461V7.20761Z" fill="#E30613"/>
                  </svg>
              </span>
            </div>
            <div class="min-checkbox-content d-flex justify-content-center align-items-center m-3 px-1" [ngStyle]="{'border': questions.Formule == 'MRH Premium' ? '1px solid #E30613' : 'none'}" (click)="selectFormule('MRH Premium') ; validationInputs() ;">
                <span class="text-center">Premium</span>
                <span class="min-checkbox-selected-hide" [ngClass]="{'min-checkbox-selected':questions.Formule == 'MRH Premium'}">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.00033 0.700504C7.22062 0.698505 5.48092 1.22512 4.01116 2.21437C2.5314 3.20263 1.38153 4.60858 0.701647 6.25234C-0.00823594 7.8881 -0.188206 9.70767 0.201729 11.4484C0.531675 13.186 1.36156 14.7879 2.60136 16.0529C3.87115 17.294 5.46086 18.1483 7.20058 18.5141C8.51036 18.7849 9.86022 18.7599 11.16 18.4411C12.4598 18.1234 13.6695 17.5198 14.6993 16.6744C15.7392 15.8291 16.5691 14.7639 17.139 13.5568C17.7089 12.3487 18.0088 11.0297 17.9988 9.6937C18.0188 8.5096 17.7789 7.33552 17.299 6.25234C16.8691 5.16016 16.2292 4.16787 15.3993 3.3345C14.5294 2.51012 13.5096 1.84963 12.3998 1.38498C11.33 0.923333 10.1701 0.690511 9.00033 0.700504ZM13.5995 7.20761L7.80057 13.0142C7.74058 13.0861 7.66051 13.1451 7.58052 13.1861C7.49053 13.227 7.40064 13.249 7.30066 13.252C7.20067 13.249 7.11066 13.226 7.02067 13.184C6.94069 13.1411 6.86073 13.0811 6.80074 13.0062L4.40111 10.584C4.40111 10.568 4.40113 10.5491 4.30115 10.5281V10.3942C4.40113 10.3732 4.40111 10.3542 4.40111 10.3382L5.20091 9.57081V9.53281C5.20091 9.52382 5.30099 9.51885 5.30099 9.51885V9.53281C5.40097 9.54081 5.40095 9.55382 5.40095 9.57081L7.30066 11.4903L12.5997 6.17738C12.5997 6.16039 12.5997 6.1474 12.6997 6.13841V6.12542C12.6997 6.12542 12.7998 6.12538 12.7998 6.13737V6.18134L13.5995 6.96377V7.0197C13.6995 7.04068 13.6995 7.06368 13.6995 7.08667C13.6995 7.10965 13.6995 7.13262 13.5995 7.15461V7.20761Z" fill="#E30613"/>
                  </svg>
              </span>
            </div>
        </div>
    </div>
</div>

<div class="container-red-box text-center my-3">
    <h1 class="text-left" [ngClass]="{'text-danger': isQuestionsClicked && questions.acceptPub == null}"><span class="text-danger">*</span> Vous êtes suceptible de recevoir des offres commerciales de notre société pour des produits et services analogues à ceux que vous avez commandés. Merci d'indiquer votre choix en cochant la case correspondante :
    </h1>
    <mat-radio-group aria-label="Select an option" color="primary" (change)="validationInputs() ; " [(ngModel)]="questions.acceptPub">
        <mat-radio-button class="m-3" [value]="true">OUI j’accepte</mat-radio-button>
        <mat-radio-button [value]="false">NON je refuse</mat-radio-button>
    </mat-radio-group>
</div>
</div>