<div id="tarification">
    <div class="step-content">
        <div class="header">
            <h1>Tarification</h1>
        </div>
        <div class="m-5">
            <div class="fixed-cotisation-show">
                <div class=" mb-1">
                    <mat-progress-bar *ngIf="tarificationLoading" mode="indeterminate"></mat-progress-bar>
                    <div class="d-flex flex-row justify-content-center align-items-baseline mt-4">
                        <h3 class="MoneyBig" *ngIf="typeCotisation && tarificationResponse.Total">{{tarificationResponse.Total.total_ttc_hors_gareat | currency:currency:'symbol':formatCurrency:'fr-FR'}}</h3>
                        <h3 class="MoneyBig" *ngIf="!typeCotisation && tarificationResponse.Total">{{tarificationResponse.Total.total_ttc_Mensuel | currency:currency:'symbol':formatCurrency:'fr-FR'}}</h3>
                    </div>
                    <div class="d-flex flex-row justify-content-center align-items-baseline mt-1">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <h3 [ngClass]="{h3red: !typeCotisation}">Cotisation mensuelle</h3>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center mr-5 ml-5 mt-2">
                            <span class=" slidetoggle d-inline-block" style="scale:2.3;">
                        <div class="container-switch" >
                          <label class="switch space-switch"><input type="checkbox"
                           [(ngModel)]="typeCotisation" ><span class="slider round"></span>
                            </label>
                        </div>
                        </span>
                    </div>
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <h3 [ngClass]="{h3red: typeCotisation}">Cotisation Annuelle</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-baseline mt-5">
            <h1 class="title"> Garanties Obligatoires</h1>
        </div>


        <div *ngFor="let garantieObligatoire of garanties_obligatoires" class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12  align-items-center justify-content-start">
                        <div>
                            <p class="description">{{garantieObligatoire.label}}</p>
                            <h3>{{garantieObligatoire.description}}
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end ">
                        <button *ngIf="tarificationResponse.ventilation" class="btntarif">{{tarificationResponse.ventilation![garantieObligatoire.code]!.montant_ttc | currency:currency:'symbol':formatCurrency:'fr-FR'}} </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12  align-items-center justify-content-start">
                        <div>
                            <p class="description">Incendie et Evènements assimilés</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12  align-items-center justify-content-start">
                        <div>
                            <p class="description">Evènements climatiques</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Catastrophes naturelles</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Catastrophes technologiques</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Attentats (hors GAREAT)</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">GAREAT</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Dégâts des eaux</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Responsabilité Civile Incendie</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Responsabilité Civile Vie Privée</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Bris de Glace</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Défense pénale et recours</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Responsabilité Propriétaire d'immeuble et Co Propriétaire Non Occupant</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
            <div class="container">
                <div class="row justify-content-center align-items-center mt-3 garantiesOblig">
                    <div class="col-lg-10 col-md-12 d-flex align-items-center justify-content-start">
                        <div>
                            <p class="description">Dommages électriques</p>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer
                                accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-items-center justify-content-end flex-nowrap ">
                        <button class="btntarif">27,84 €</button>
                    </div>
                </div>
            </div>
        </div> -->


        <div class="d-flex flex-row justify-content-center align-items-center mt-5">
            <h1 class="title"> Garanties Optionnelles</h1>
        </div>

        <div class="togglecontainer mb-5">
            <div class="d-flex flex-row flex-wrap justify-content-center mb-4">
                <ng-container *ngFor="let garantieOpt of garanties_Optionnelles">
                    <div *ngIf="tarificationResponse.ventilation && tarificationRequest.habitation.typeHabitation && garantieOpt.typeHabitationCondition.includes(tarificationRequest.habitation.typeHabitation) && tarificationRequest.habitation.typeProspect && garantieOpt.typeProspectCondition.includes(tarificationRequest.habitation.typeProspect)" class="col-lg-4 mt-4">
                        <slide-toggle (slideValue)="getSlideValue($event,garantieOpt.modelKEy)" title="{{garantieOpt.label}}" description="{{garantieOpt.description}}" [value]="tarificationRequest[garantieOpt.modelKEy]" [extraInfo]="getExtraInfoValue(garantieOpt)" [disabled]="garantieOpt.disabledCondition.includes(tarificationRequest.Formule!) || garantieOpt.disabledCondition.includes(tarificationRequest.habitation.typeProspect)"></slide-toggle>
                    </div>
                </ng-container>
                <!-- <div class="col-lg-4 mt-4">
                <slide-toggle title="Valeur à neuf à 100%" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+0,00 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle   title="Vol"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+0,00 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle   title="Assistance"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+2,74 €/année"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle   title="Protection Juridique"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+0,00 €/année"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle   title="Canalisations enterrées"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+0,00 €/année"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle   title="Débordements et refoulements d'égoûts"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle  title="Bris de glace sur objet mobilier"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+0,00 €/année"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle   title="Bris de glace sur véranda"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+5,66 €/année"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle   title="Panneaux photovoltaïques"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle   title="Piscine ou SPA"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+0,00 €/année"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle   title="Biens en extérieurs"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+5,66 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                <slide-toggle id="slidetoggle1"  title="Extension chambre d'hôte ou gite"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle id="slidetoggle1"  title="Extension location saisonnière"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle id="slidetoggle1"  title="Perte de loyer"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle id="slidetoggle1"  title="RC Assistante Maternelle"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle id="slidetoggle1"  title="RC Aidant"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle id="slidetoggle1"  title="Kiosque"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle id="slidetoggle1"  title="Terrasse ajoutée"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle id="slidetoggle1"  title="RC Fête familiale"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div> -->
                <!-- <div class="col-lg-4 mt-4">
                    <slide-toggle id="slidetoggle1"  title="PNO Location saisonnière"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div> -->
                <!-- <div class="col-lg-4 mt-4">
                    <slide-toggle id="slidetoggle1"  title="Contenu professionnel"  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id mollis ipsum. Integer accumsan massa sed lectus pharetra porttitor. Nunc sapien augue, scelerisque eu tincidunt tempor" extraInfo="+4,02 €/année" ></slide-toggle>
                </div> -->


            </div>
        </div>

        <div class="d-flex flex-row justify-content-center align-items-center mt-3 souscripteurbg">
            <div class="container ">
                <div class="d-flex flex-row justify-content-center align-items-center mt-3 ">
                    <div class="m-2">
                        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M22.085 44.17C34.2825 44.17 44.17 34.2825 44.17 22.085C44.17 9.88745 34.2825 0 22.085 0C9.88745 0 0 9.88745 0 22.085C0 34.2825 9.88745 44.17 22.085 44.17Z" fill="#292371"/>
                                <path d="M22.0845 10.8848C17.5129 10.8848 13.8026 14.595 13.8026 19.1666C13.8026 23.6499 17.3141 27.2939 21.9741 27.4264H22.3716C24.5154 27.3559 26.5479 26.4549 28.0397 24.9137C29.5315 23.3724 30.3658 21.3116 30.3664 19.1666C30.3664 14.595 26.6561 10.8848 22.0845 10.8848ZM37.0559 38.3077C32.98 42.075 27.6325 44.1651 22.0823 44.1602C16.296 44.1602 11.0398 41.9297 7.10864 38.3077C7.63868 36.298 9.07421 34.4649 11.1723 33.0515C17.2015 29.032 27.0072 29.032 32.9923 33.0515C35.1124 34.4649 36.5259 36.298 37.0559 38.3077Z" fill="#292371"/>
                                </svg>
                    </div>
                    <div class="m-2 d-flex justify-content-center align-items-baseline">
                        <h3 class="souscripteur mb-0">Souscripteur</h3>
                        <div class="position-absolute" *ngIf="generateDevis.opportuniteRef">
                            <div class="souscripteur-actions">
                                <button *ngIf="disableInfoSouscripteurInputs;else resetProspect" mat-icon-button class="icon-update-prospect" (click)="allowEditProspect()">
                                    <mat-icon class="mat-18">edit</mat-icon>
                                </button>
                                <button *ngIf="!disableInfoSouscripteurInputs" mat-raised-button class="btn-update-prospect" (click)="updateProspect()" [disabled]="updateProspectLoading">
                                    <mat-icon *ngIf="!updateProspectLoading" class="mat-18">save</mat-icon>
                                    <mat-progress-spinner  *ngIf="updateProspectLoading" class="mr-2 d-inline-block" diameter="15" mode="indeterminate"></mat-progress-spinner>
                                    <span>{{ updateProspectLoading ? 'En cours...' : 'Enregistrer' }}</span>
                                </button>
                                <ng-template #resetProspect>
                                    <button mat-icon-button [disabled]="updateProspectLoading" class="icon-reset-prospect" (click)="resetProspectInformation()">
                                      <mat-icon class="mat-18">settings_backup_restore</mat-icon>
                                    </button>
                                </ng-template>
                            </div>
                        </div>
                    </div>


                </div>
                <!-- <div class="col-2 d-flex align-items-center justify-content-end flex-nowrap "> -->


                <form souscripteurForm="ngForm"></form>
                <div class="d-flex flex-row flex-wrap justify-content-start mb-4">
                    <div class="col-lg-4 mt-4">
                        <div class="field-container">
                            <mat-label><span class="text-danger">* </span>Civilité</mat-label>
                            <mat-form-field class="field-element" appearance="outline">
                                <mat-select name="title" required [(ngModel)]="generateDevis.client.title" #title="ngModel" [disabled]="disableInfoSouscripteurInputs">
                                    <mat-option value="Mr">Mr</mat-option>
                                    <mat-option value="Mme">Mme</mat-option>
                                </mat-select>
                                <mat-error *ngIf="title.invalid && (title.dirty || title.touched)">
                                    <mat-error *ngIf="title.errors?.required">
                                        Civilité est requis.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-4">
                        <div class="field-container">
                            <mat-label><span class="text-danger">* </span>Nom</mat-label>
                            <mat-form-field class="field-element" appearance="outline">
                                <input matInput name="firstName" [(ngModel)]="generateDevis.client.firstName" required #firstName="ngModel" [disabled]="disableInfoSouscripteurInputs" (change)="validateSubscriberInfosFrom(generateDevis.client.firstName)">
                                <mat-error *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                                    <mat-error *ngIf="firstName.errors?.required">
                                        Nom est requis.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-4">
                        <div class="field-container">
                            <mat-label><span class="text-danger">* </span>Prénom</mat-label>
                            <mat-form-field class="field-element" appearance="outline">
                                <input matInput name="lastName" [(ngModel)]="generateDevis.client.lastName" required #lastName="ngModel" [disabled]="disableInfoSouscripteurInputs" (change)="validateSubscriberInfosFrom(generateDevis.client.lastName)">
                                <mat-error *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                                    <mat-error *ngIf="lastName.errors?.required">
                                        Prénom est requis.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-4">
                        <div class="field-container">
                            <mat-label><span class="text-danger">* </span>Adresse</mat-label>
                            <mat-form-field class="field-element" appearance="outline">
                                <input matInput name="streetName" [(ngModel)]="generateDevis.client.address.streetName" required #streetName="ngModel" [disabled]="disableInfoSouscripteurInputs" (change)="validateSubscriberInfosFrom(generateDevis.client.address.streetName)">
                                <mat-error *ngIf="streetName.invalid && (streetName.dirty || streetName.touched)">
                                    <mat-error *ngIf="streetName.errors?.required">
                                        Adresse est requis.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-4">
                        <div class="field-container">
                            <mat-label><span class="text-danger">* </span>Code postal</mat-label>
                            <mat-form-field class="field-element" appearance="outline">
                                <input matInput type="text" pattern="[0-9]*" name="postalCode" [(ngModel)]="generateDevis.client.address.postalCode" [disabled]="disableInfoSouscripteurInputs" required #postalCode="ngModel" (change)="validateSubscriberInfosFrom(generateDevis.client.address.postalCode)"
                                    maxLength="5" min="1" (keydown)="onKeyDown($event)">
                                <mat-error *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
                                    <mat-error *ngIf="postalCode.errors?.required">
                                        Code postal est requis.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-4">
                        <div class="field-container">
                            <mat-label><span class="text-danger">* </span>Ville</mat-label>
                            <mat-form-field class="field-element" appearance="outline">
                                <input matInput name="city" [(ngModel)]="generateDevis.client.address.city" #city="ngModel" required [disabled]="disableInfoSouscripteurInputs" (change)="validateSubscriberInfosFrom(generateDevis.client.address.city)">
                                <mat-error *ngIf="city.invalid && (city.dirty || city.touched)">
                                    <mat-error *ngIf="city.errors?.required">
                                        Ville est requis.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-4">
                        <div class="field-container">
                            <mat-label><span class="text-danger">* </span>Email</mat-label>
                            <mat-form-field class="field-element" appearance="outline">
                                <input matInput name="email" [(ngModel)]="generateDevis.client.email" #email="ngModel" required [disabled]="disableInfoSouscripteurInputs" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" (change)="validateSubscriberInfosFrom(generateDevis.client.email)">
                                <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                                    <mat-error *ngIf="email.errors?.required">
                                        Email est requis.
                                    </mat-error>
                                    <mat-error *ngIf="email.errors?.pattern">
                                        Format email invalide.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-4">
                        <!-- <div class="field-container">
                            <mat-label><span class="text-danger">* </span>Numéro téléphone</mat-label>
                            <mat-form-field class="field-element" appearance="outline">
                                <input matInput name="mobile" [(ngModel)]="generateDevis.client.mobile" (change)="validateSubscriberInfosFrom(generateDevis.client.mobile)">
                                <mat-error *ngIf=""></mat-error>
                                <mat-icon class="icon" matPrefix>phone</mat-icon>
                            </mat-form-field>
                        </div> -->
                        <div class="field-container">
                            <mat-label><span class="text-danger">* </span>Numéro téléphone</mat-label>
                            <form [formGroup]="phoneForm">
                                <div class="wrapper">
                                    <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
                                SearchCountryField.Iso2,
                                SearchCountryField.Name
                              ]" [selectFirstCountry]="false" [searchCountryPlaceholder]="'Rechercher un pays'" [selectedCountryISO]="CountryISO.France" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National"
                                        name="phone" formControlName="phone">
                                    </ngx-intl-tel-input>
                                    <mat-error *ngIf="phoneForm.invalid">
                                        <mat-error *ngIf="phoneForm.controls.phone?.touched && !phoneForm.value.phone">
                                            <span class="error-message-ngx-tel">Numéro téléphone est requis.</span>
                                        </mat-error>
                                        <mat-error *ngIf="phoneForm.value.phone && phoneForm.controls.phone.invalid">
                                            <span class="error-message-ngx-tel">Format de numéro téléphone est invalid.</span>
                                        </mat-error>
                                    </mat-error>
                                </div>

                            </form>
                        </div>


                        <!-- <mat-label><span class="text-danger">* </span>Numéro téléphone</mat-label> -->
                        <!-- <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text" id="inputGroup-sizing-sm"><mat-icon class="icon">local_phone</mat-icon></span>
                                    </div>
                                    <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1">
                                  </div> -->
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>