export class PrincipalInformation{
  postalCode: string = "";
  city: string = "";
  typeHabitation: string = "";
  typeProspect: string = "";
  residence: string = "";
  taille_habitation: string = "";
  numContrat: string = "";

  toJSON(): string {
    return JSON.stringify(this);
  }

  static fromJSON(jsonString: string): PrincipalInformation {
    const parsedJson = JSON.parse(jsonString);
    const principalInfo = new PrincipalInformation();

    Object.assign(principalInfo, parsedJson);

    return principalInfo;
  }
}
