export class ComplementaryInformation{
  sinistres:          boolean = false;
  resilie:            boolean = false;
  zone_risque:        boolean = false;
  denivele_terrain:   boolean = false;
  dur:                boolean = false;
  bois:               boolean = false;
  absence_habitation: boolean = false;
  etat_bien:          string = "";
  annee_construction: number | null = null;
  effectiveDate: string = formatDate(new Date());
  effectiveDateText: string = "" ; 
  certificat_file: File | null = null;
  [key: string]: boolean | string | number | File | null;


  // toJSON(): string | number | boolean | null {
  //   return JSON.stringify(this);
  // }

  // static fromJSON(jsonString: string): ComplementaryInformation {
  //   const parsedJson = JSON.parse(jsonString);
  //   const complementaryInfo = new ComplementaryInformation();

  //   Object.assign(complementaryInfo, parsedJson);

  //   return complementaryInfo;
  // }
}


function formatDate(date: Date): string {
  const day: string | number = date.getDate() + 1;
  const month: string | number = date.getMonth() + 1;
  const year: number = date.getFullYear();

  const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;

  const formattedDate: string = `${formattedDay}/${formattedMonth}/${year}`;

  return formattedDate;
}
