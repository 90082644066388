import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorDialogComponent } from './error-dialog.component';
import { MatIconModule } from '@angular/material/icon';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  constructor(private snackBar: MatSnackBar) {}

  openErrorDialog(errorMessage: string, buttonText: string, isError: boolean): void {
    this.snackBar.openFromComponent(ErrorDialogComponent, {
      data: {
        errorMessage,
        buttonText,
        isError
      },
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }

  closeErrorDialog(): void {
    this.snackBar.dismiss() ; 
  }
}
