export class TarificationResponse {
    Total: {
        total_ttc: number;
        total_ttc_hors_gareat:number;
        total_ttc_Mensuel: number;
    } | undefined;
    
    garanties_optionnelles: {
        [key: string]: {
            label: string;
            montant_ttc: number | null;
        } | null;
    } | undefined;
    
    references: {
        tarifRef: string;
    } | undefined;
    
    ventilation: {
        [key: string]: {
            label: string;
            montant_ttc: number;
        } | null;
    } | undefined;
}