import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Sign } from '../_models/Sign';

@Injectable({
  providedIn: 'root'
})
export class B2cService {

  private apiUrl: string = environment.API_URL;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
  */
  constructor(private _httpClient: HttpClient) { }


  /**
   * Permettant de generer un URL yousign
   * @param {Sign} sign
   */
  sign(sign: Sign): Observable<any> {
    const headers = new HttpHeaders();

    return this._httpClient.request<any>('post', `${this.apiUrl}/yousign/e-yousign`,
        {
            headers: headers,
            body: sign
        }
    );
  }
}
