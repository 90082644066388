import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit, OnChanges {
  @Output() slideValue: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() inputNumberValueEmit: EventEmitter<number> = new EventEmitter<number>();
  @Output() certificat_file: EventEmitter<File> = new EventEmitter<File>();;
  @Input() title: string = '';
  @Input() icon:boolean =false;
  @Input() button:boolean = false;
  @Input() image?: string = '';
  @Input() description?: string = '';
  @Input() extraInfo: any = '';
  @Input() value: any = false;
  @Input() availableValue:boolean = false;
  @Input() titleIcon: string = '';
  @Input() inputNumber: boolean = false ;
  @Input() inputNumberTitle?: string = '' ;
  @Input() inputNumberValue: any = undefined;
  @Input() disabled: boolean = false;
  @Input() isRequiredChoosen: boolean = false;
  @Input() required: boolean = false;
  @ViewChild("slideToggleValidation") slideToggleValidation: MatInput = {} as MatInput;

  currency: string = environment.currency;
  formatCurrency: string = environment.formatCurrency;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
   if(this.isRequiredChoosen)
   this.slideToggleValidation
  }

  emitValue(event: any, ): void {
    if(typeof this.value === 'boolean')
    this.slideValue.emit(this.value)
  }

  emitInputNumberValue(event: any, ): void {
    this.inputNumberValue = parseFloat(this.inputNumberValue) ; 
    if(typeof this.inputNumberValue === 'number')
    this.inputNumberValueEmit.emit(this.inputNumberValue)
  }

  onInputChange(event:any): void {
    this.inputNumberValue = event ;
}

onKeyDownSurface(event: KeyboardEvent): void {
  const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight','.'];
  if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
    return;
  }
  if (allowedKeys.indexOf(event.key) === -1) {
    event.preventDefault();
  }
}

onFileSelected(event: any) {
  const file: File = event.target.files[0];
  this.certificat_file.emit(file) ; 
  
}

}
