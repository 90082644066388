<app-loading-screen *ngIf="loadingScreenService.isLoading$ | async"></app-loading-screen>
<div id="b2b">
    <div *ngIf="stepIndex !== 3" class="header">
        <img src="/assets/logos/logo.png" alt="">
    </div>
    <div class="steps-container">

        <mat-horizontal-stepper #stepper color="primary" [linear]="isLinear" mat-stepper [selectedIndex]="stepIndex">
            <ng-template matStepperIcon="edit">
                <ng-container>
                    <mat-icon>check</mat-icon>
                </ng-container>
            </ng-template>
            <mat-step [completed]="stepIndex > 0" [stepControl]="informationForm">

                <ng-template matStepLabel><span class="step-title" *ngIf="stepIndex == 0">Informations principales</span></ng-template>

                <principal-information [informationForm]="informationForm"></principal-information>

                <div class="navigations">
                    <button mat-button disabled class="btn-previous" matStepperPrevious>Retour</button>
                    <button mat-button class="btn-next" (click)="nextStep(1)">Suivant</button>
                </div>
            </mat-step>
            <mat-step [completed]="stepIndex > 1">
                <ng-template matStepLabel><span class="step-title" *ngIf="stepIndex == 1">Informations complémentaires</span></ng-template>

                <complementary-information [isComplemnetaryInformationClicked]="isComplemnetaryInformationClicked" [complementaryInformation]="complemnetaryInformation" (emitComplementaryInfo)="getComplementaryInformation($event)"></complementary-information>
                <div class="navigations">
                    <button mat-button class="btn-previous" (click)="previousStep(0)">Retour</button>
                    <button mat-button class="btn-next" (click)="nextStep(2)">Suivant</button>
                </div>
            </mat-step>
            <mat-step [completed]="stepIndex > 2">
                <ng-template matStepLabel><span class="step-title" *ngIf="stepIndex == 2">Questions subsidiaires</span></ng-template>
                <questions [isQuestionsClicked]="isQuestionsClicked" [questions]="questions" (emitQuestions)="getQuestions($event)" [typeHabitation]="informationForm.value.typeHabitation"></questions>
                <div class="navigations">
                    <button mat-button class="btn-previous" (click)="previousStep(1)">Retour</button>
                    <button mat-button class="btn-next" (click)="nextStep(3)">Suivant</button>
                </div>
            </mat-step>

            <mat-step [completed]="stepIndex > 3">
                <ng-template matStepLabel><span class="step-title" *ngIf="stepIndex == 3">Tarification </span></ng-template>
                <tarification [disableInfoSouscripteurInputs]="disableInfoSouscripteurInputs" (disableInfoSouscripteurInputsChange)="getDisableInfoSouscripteurValue($event)" [tarificationLoading]="tarificationLoading" [generateDevis]="generateDevis" [tarificationResponse]="tarificationResponse"
                    [tarificationRequest]="tarification" (tarificationRequestChange)="getTarificationRequestValues($event)"></tarification>
                <div class="navigations">

                    <button mat-button class="btn-previous" (click)="previousStep(2)">Retour</button>
                    <button mat-button class="btn-next" (click)="onGenerateDevis(false)" [disabled]="devisDownloadLoading || disableButtonsDevis">
                        <div class="button-download-devis-content">
                            <img *ngIf="!devisDownloadLoading" src="/assets/global/download-icon.svg" alt="Download">
                            <mat-progress-spinner *ngIf="devisDownloadLoading" diameter="20" mode="indeterminate"></mat-progress-spinner>
                            <span class="ml-2">{{ devisDownloadLoading ? 'En cours...' : 'Télécharger Devis' }}</span>
                        </div>

                    </button>
                    <button mat-stroked-button (click)="onGenerateDevis(true)" [disabled]="devisSendingLoading || disableButtonsDevis">
                        <div class="button-send-devis-content">
                        <img *ngIf="!devisSendingLoading" src="/assets/global/send-icon.svg" alt="Send">
                        <mat-progress-spinner *ngIf="devisSendingLoading" diameter="20" mode="indeterminate"></mat-progress-spinner>
                        <span class="ml-2">{{ devisSendingLoading ? 'En cours...' : 'Envoyer devis par E-mail' }}</span>
                        </div>

                      </button>

                    <button mat-button class="btn-next" (click)="subscribe()" [disabled]="!generateDevis.sendDevis || disableButtonsDevis">Souscription</button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>

    </div>
</div>