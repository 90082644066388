import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-pages-b2c',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponentb2c implements OnInit {
  // @ViewChild("stepper") stepper: any;

  isLinear:  boolean = false;
  stepIndex: number  = 0;

  ngOnInit(): void {
   
  } 

}
