import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-dialog',
  templateUrl : './error-dialog.component.html' , 
  styleUrls: ['./error-dialog.component.scss']

})
export class ErrorDialogComponent implements OnInit {

  constructor(public snackBarRef: MatSnackBarRef<ErrorDialogComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void {
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
