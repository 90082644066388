<!-- <div class="card" [ngClass]="{cardselected: value}"> -->
<div class="card-content" [ngClass]="{'cardselected' :value}">
    <span *ngIf="title" class="title mb-1" [ngClass]="{'text-danger': isRequiredChoosen && !value}">{{title}}</span>
    <img *ngIf="image" class="mt-2" src="assets/svg-question-component/{{image}}.svg" alt="Icon">
    <span *ngIf="description" class="description mt-2">{{description}}</span>
    <mat-icon class="icon mt-3" *ngIf="icon" [matTooltip]="titleIcon">error_outline</mat-icon>
    <div class=" mt-3 justify-content-center align-items-center d-flex flex-nowrap">
        <h3 *ngIf="extraInfo" [ngClass]="{'extrainfoselected': value , 'extrainfo' :!value}">{{extraInfo | currency:currency:'symbol':formatCurrency:'fr-FR'}}/année</h3>
    </div>
    <span class=" slidetoggle d-inline-block mt-3">
        <div class="container-switch">
          <label class="switch space-switch">
            <input type="checkbox" name="switch" [(ngModel)]="value" (change)="emitValue($event)" [disabled]="disabled">
            <span class="slider round"><span class="slider-text-checked">Oui</span><span class="slider-text-unchecked">Non</span></span>
    </label>
</div>
</span>
<div class=" mt-3 justify-content-center align-items-center d-flex flex-nowrap">
    <button *ngIf="button && value" class="cardSelected" (click)="fileInput.click()">
        <img src="/assets/global/download-icon.svg" alt="Download">
        <label class="title ml-2">Certificat de radiation</label>
        </button>
    <input type="file" accept="application/pdf" #fileInput style="display:none" (change)="onFileSelected($event)">
</div>

<div class=" mt-3 justify-content-center align-items-center d-flex flex-nowrap">
    <div *ngIf="inputNumber" class="d-flex justify-content-center my-3">
        <div class="field-container text-left">
            <mat-label><span class="text-danger">*</span>{{inputNumberTitle}}</mat-label>
            <mat-form-field class="field-element" appearance="outline">
                <input matInput type="text" #slideToggleValidation id="inputNumber" name="inputNumber" min="1" autofocus oninput="validity.valid||(value=value);" [(ngModel)]="inputNumberValue" (ngModelChange)="onInputChange($event)" (change)="emitInputNumberValue($event)"
                    [required]="required && inputNumber" (keydown)="onKeyDownSurface($event)">
                <span matSuffix class="secondairy-text">m²</span>
                <!-- <mat-error *ngIf="informationForm.controls.surface.invalid">
                    <mat-error *ngIf="informationForm.controls.surface.errors?.required">
                        Surface de logement est requis.
                    </mat-error> -->
            </mat-form-field>
        </div>
    </div>
</div>

</div>
<!-- </div>  -->