// @ts-ignore
import * as CryptoJS from 'crypto-js';

import { CookieService } from 'ngx-cookie-service';


const secretKey = '2JiqV4Ss74UJKGebejX9563gRdA6eZhb6eXwrEm2UVcE8spVpsmtMrDwzPRQ07PvDE3Krw12JqWA0SYJIsgFA63WP1QSonXHnVyo@pRH6';


function encrypt(text: string): string {
  const encryptedData = CryptoJS.AES.encrypt(text, secretKey).toString();
  return encryptedData;
}


export function decrypt(encryptedData: string): string {
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

export function encryptAndStore(data : string , key: string, cookieService : CookieService) : void {
    cookieService.set(key,encrypt(data)); 
}
