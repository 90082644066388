<div id="complementary-information">
    <div class="step-content">
        <div class="header">
            <h1>Informations complémentaires</h1>
        </div>


        <div class=" d-flex flex-row justify-content-center align-items-center mt-5">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="d-flex flex-column justify-content-start align-items-center">
                    <mat-label><span class="text-danger">*</span>Date d'effet du contrat</mat-label>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <app-datepicker [complementaryInformation]="complementaryInformation"></app-datepicker>
                    
                </div>
                <span class="text-center">{{complementaryInformation.effectiveDateText}}</span>
            </div>
        </div>

        <div class="togglecontainer">
            <div class="d-flex flex-row flex-wrap justify-content-center mb-4">
                <div class="col-lg-4 mt-4">
                    <slide-toggle (slideValue)="getSlideValue($event, false,'sinistres',false); validationInputs()" [availableValue]="false" [value]="complementaryInformation.sinistres" description="Avez-vous eu plus de 2 sinistres lors des 36 derniers mois ?"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle (certificat_file)="complementaryInformation.certificat_file = $event" (slideValue)="getSlideValueCertificate($event)" [button]="true" [availableValue]="true" [value]="complementaryInformation.resilie" description="Avez-vous été résilié par un autre assureur sauf pour cause de non paiement ?"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle (slideValue)="getSlideValue($event, false,'zone_risque',false); validationInputs()" [availableValue]="false" [value]="complementaryInformation.zone_risque" description="Votre habitation se situe t'elle en zone inondable ou à risque ?"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle (slideValue)="getSlideValue($event, false,'denivele_terrain',false); validationInputs()" [availableValue]="false" [value]="complementaryInformation.denivele_terrain" description="Votre habitation est elle proche d'un dénivelé de terrain (>10m)?"></slide-toggle>
                </div>
                <div class="col-lg-4 mt-4">
                    <slide-toggle (slideValue)="complementaryInformation.absence_habitation =$event; validationInputs()" [availableValue]="true" [value]="complementaryInformation.absence_habitation" [titleIcon]="titleBien" description="Est-vous absent de votre habitation plus de 60 jours par an ?"></slide-toggle>
                </div>

            </div>
        </div>
        <div class="container-fluid">
            <div class="d-flex flex-column justify-content-center">
                <div class="d-flex flex-row justify-content-center">
                    <h3 class="title" [ngClass]="{'text-danger': isComplemnetaryInformationClicked && !complementaryInformation.etat_bien}"><span class="text-danger">*</span> Quel est l'état de votre bien ?
                        <mat-icon class="icon" [matTooltip]="titleBien">error_outline</mat-icon>
                    </h3>
                </div>
                <div class="d-flex flex-row flex-wrap flex-xl-nowrap justify-content-center">
                    <card-checkbox title="Vétuste" [coloredIcons]="1" [greyIcons]="3" [index]="1" [isSelected]="complementaryInformation.etat_bien === 'Vétuste'" (cardSelected)="onCardSelected($event); validationInputs()"></card-checkbox>
                    <card-checkbox title="Moyen" [coloredIcons]="2" [greyIcons]="2" [index]="2" [isSelected]="complementaryInformation.etat_bien === 'Moyen'" (cardSelected)="onCardSelected($event); validationInputs()"></card-checkbox>
                    <card-checkbox title="Correct" [coloredIcons]="3" [greyIcons]="1" [index]="3" [isSelected]="complementaryInformation.etat_bien === 'Correct'" (cardSelected)="onCardSelected($event); validationInputs()"></card-checkbox>
                    <card-checkbox title="Bon" [coloredIcons]="4" [greyIcons]="0" [index]="4" [isSelected]="complementaryInformation.etat_bien === 'Bon'" (cardSelected)="onCardSelected($event); validationInputs()"></card-checkbox>
                </div>
            </div>
        </div>

        <div class=" d-flex flex-row justify-content-center align-items-center mt-5">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="d-flex flex-column justify-content-start align-items-center">
                    <mat-label><span class="text-danger">*</span>Année de construction</mat-label>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center">

                    <mat-form-field appearance="outline">
                        <input matInput [matDatepicker]="dpFilter" placeholder="YYYY" [formControl]="selectYear" name="selectYear" required [max]="dateNow" (change)="getDateValue($event); validationInputs()" (input)="onInputDateValue($event);" (keydown)="onKeyDownDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="dpFilter"></mat-datepicker-toggle>
                        <mat-hint>YYYY</mat-hint>
                        <mat-datepicker #dpFilter startView="multi-year" panelClass="picker-year" nextYearLabel (yearSelected)="chosenYearHandlerFilter($event, dpFilter)">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="togglecontainer">
            <div class="d-flex flex-row flex-wrap justify-content-center mb-4">
                <div class="col-lg-4 mb-2 mt-4">
                    <slide-toggle (slideValue)="getSlideValue($event, false,'dur',true); validationInputs()" [availableValue]="false" [value]="complementaryInformation.dur" [titleIcon]="titleHabitation" title="Votre habitation est-elle construite à plus de 90% en dur ?"
                        [icon]="true"></slide-toggle>
                </div>
                <div class="col-lg-4 mb-2 mt-4">
                    <slide-toggle (slideValue)="complementaryInformation.bois = $event; validationInputs()" [availableValue]="true" [value]="complementaryInformation.bois" title="Votre maison est-elle en bois ?"></slide-toggle>
                </div>
            </div>
        </div>


    </div>
</div>