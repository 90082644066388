<div [ngClass]="{'custom-error-dialog': data.isError, 'custom-warn-dialog': !data.isError}">
    <div class="error-icon">
      <mat-icon *ngIf="data.isError">error</mat-icon>
      <mat-icon *ngIf="!data.isError">warning</mat-icon>
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <span  class="custom-message">{{ data.errorMessage }}</span>
        <button mat-icon-button color="primary" (click)="close()" class="close-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
  