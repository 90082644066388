
export class AdresseClient{
  description?: string | null = undefined;
  streetNumber?:string | null = undefined;
  streetName:   string | null = null;
  city:         string | null = null;
  country?:     string | null = undefined;
  postalCode:   string | null = null;
}

export class Client{
  typeProspect:   string | null = null;
  firstName:      string | null = null;
  lastName:       string | null = null;
  email:          string | null = null;
  mobile:         string | null = null;
  birthDate?:     string | undefined = undefined;
  AccountHolder?: string | undefined = undefined;
  title?:         string | undefined = undefined;
  address:        AdresseClient = new AdresseClient();
}

export class GenerateDevis{
  client: Client = new Client();
  opportuniteRef?: string | null = null;
  tarifRef:        string | undefined = undefined;
  sendDevis?:       boolean | undefined = false;

  [key: string]: string | null | undefined | Client | boolean;
}
