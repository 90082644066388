import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import * as storageService from './pages/pages/_utils.ts/dataStorage' ; 
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SouscriptionGuard implements CanActivate {

    stepIndex : number = 0 ; 
    canActivateSouscription : boolean = false ; 
    apiKey : string = "" ; 
  constructor(private _router: Router,private _cookieService: CookieService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
        
    
    if (this._cookieService.check('6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8')){
        this.apiKey = "" ; 
        
        this.apiKey = storageService.decrypt(this._cookieService.get('6GrL131shBI6OCImJkM1v8UkVeWeXYlKdD3Khp8'));
        if (this._cookieService.check('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD')) {
            this.stepIndex = parseInt(storageService.decrypt(this._cookieService.get('xHbLlEEYa0xXZSpLXl2OE2Eh1YWKhsPD')));
            if (this.stepIndex == 4 || this.stepIndex == 5 ) {
                this.canActivateSouscription = true ;
            } else {
                this.canActivateSouscription = false ;
            }
        } else {
            this.canActivateSouscription  = false ; 
        }
        
    } else {
        this.canActivateSouscription  = false ; 
         
    }
    

    if (this.canActivateSouscription) {
      return true;
    } else {
        if (this.apiKey != "") {
            this._router.navigate(['/'], { queryParams: { partnership_id: this.apiKey} });
        }else {
            this._router.navigate(['']);
        }
      return false;
    }
  }
}
