export class InfoSouscriptionResponse{
    recapitulative: Recap = new Recap();
}

export class Recap{
    date_debut: string | null = null;
    date_fin: string | null = null ;
    frais_supp: { [key: string]: Frais } = {};
    montant_annuel: number | null = null ; 
    montant_annuel_frais: number | null = null ;
    montant_mensuel: number | null = null ; 
    montant_mensuel_frais: number | null = null ;
    premiere_echeance: number | null = null ; 
    montant_annuel_hors_gareat: number | null = null ; 
}

export class Frais {
    amortized : boolean | null = null ; 
    label: string = "" ; 
    perUnit: boolean | null = null ; 
    pourcentage: boolean | null = null ; 
    recurrent: boolean | null = null ;
    val: number | null = null ;
    val_mensuelle: number | null = null ;
}