import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages/pages.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrincipalInformationComponent } from './principal-information/principal-information.component';
import { ComplementaryInformationComponent } from './complementary-information/complementary-information.component';
import { QuestionsComponent } from './questions/questions.component';
import { GarantiesComponent } from './garanties/garanties.component';
import { TarificationComponent } from './tarification/tarification.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { CardCheckboxComponent } from './card-checkbox/card-checkbox.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { PrincipalInformationService } from './principal-information/_services/principal-information.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiKeyInterceptor } from './pages/apikey.interceptor';
import { LoadingScreenService } from '../shared-components/loading-screen/loading-screen.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DatepickerComponent } from './datepicker/datepicker.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [

    ]
  }
];
@NgModule({
  declarations: [PagesComponent, PrincipalInformationComponent, ComplementaryInformationComponent, QuestionsComponent, GarantiesComponent, TarificationComponent, SlideToggleComponent, CardCheckboxComponent, DatepickerComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    HttpClientModule,
    NgxIntlTelInputModule
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS, useClass: ApiKeyInterceptor, multi: true
    },
    PrincipalInformationService,
    LoadingScreenService
  ]
})
export class PagesModule { }
