import { Component, OnInit , Input , OnChanges , SimpleChanges } from '@angular/core';
import { ErrorDialogService } from '../../shared-components/error-dialog/error.dialog.service';
import { FormControl} from '@angular/forms';


import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { ComplementaryInformation } from '../complementary-information/_models/ComplementaryInfo';
import { Moment } from 'moment';

const MY_DATE_FORMATS_EFFECT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_LOCALE, useValue: 'fr' },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS_EFFECT},
  ],
})
export class DatepickerComponent implements OnInit , OnChanges {
  @Input() complementaryInformation: ComplementaryInformation = new ComplementaryInformation();
  dateRegex: RegExp = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
  dateNow = new Date();
  minEffectiveDate = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate() + 1);
  maxEffectiveDate = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate() + 90);
  dateEffet = new FormControl()
  

  constructor(private _errorService: ErrorDialogService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.isComplemnetaryInformationClicked?.currentValue){
      this.dateEffet.markAsTouched()
    }
  }

  ngOnInit(): void {
    this.dateEffet = new FormControl(moment(this.complementaryInformation.effectiveDate,'DD/MM/YYYY').locale('fr')) ;
    this.complementaryInformation.effectiveDateText = this.dateEffet.value.format('dddd [le] DD MMMM YYYY') ; 
  }



 
  chosenEffectiveDateHandlerFilter(event: any) {
    this.complementaryInformation.effectiveDate = this.dateEffet.value.format('DD/MM/YYYY') ;
    this.complementaryInformation.effectiveDateText = this.dateEffet.value.format('dddd [le] DD MMMM YYYY') ; 
  }


  getEffectiveDateValue(event: any): void {
    let inputValue = event.target.value;
    const [day, month, year] = inputValue.split('/').map(Number);
    const dateObject: Date = new Date(year, month - 1, day);
    if (!isNaN(dateObject.getTime())) {
      console.log(dateObject) ; 
      if (dateObject <= new Date()) {
        this._errorService.openErrorDialog("La date d'effet doit être supérieure à la date d'aujourd'hui !", 'Fermer',true);
      } else if (this.dateDifferenceInDays(new Date(),dateObject) > 90 ) {
        this._errorService.openErrorDialog("La date d'effet ne peut pas dépasser 90 jours !", 'Fermer',true);
      } else {
        let effectiveDateStrValue = this.formatDate(dateObject) ; 
        if (this.dateRegex.test(effectiveDateStrValue)) {
          this.dateEffet.setValue(moment(effectiveDateStrValue,'DD/MM/YYYY')) ;
          this.complementaryInformation.effectiveDate = this.dateEffet.value.format('DD/MM/YYYY') ; 
          this.complementaryInformation.effectiveDateText = this.dateEffet.value.locale('fr').format('dddd [le] DD MMMM YYYY') ; 
        } else {
          this._errorService.openErrorDialog("Date d'effet n'est pas valide !", 'Fermer',true);
        }
        
      }
      
    } else {
      this._errorService.openErrorDialog("Date d'effet n'est pas valide !", 'Fermer',true);
    }
  }

  onInputEffectiveDateValue(event: any){

  }


  onKeyDownEffectiveDate(event: KeyboardEvent): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight','/'];
    if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
      return;
    }
    if (allowedKeys.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  }

  formatDate(date: Date): string {
    const day: string | number = date.getDate();
    const month: string | number = date.getMonth() + 1;
    const year: number = date.getFullYear();
  
    const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
  
    const formattedDate: string = `${formattedDay}/${formattedMonth}/${year}`;
    return formattedDate;
  }

  dateDifferenceInDays(date1: Date, date2: Date): number {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    const differenceInMillis = Math.abs(date2.getTime() - date1.getTime());
    const differenceInDays = differenceInMillis / (24 * 60 * 60 * 1000);

    return differenceInDays;
}

}
